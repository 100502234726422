import {isDonationTicketDefinition} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {Text} from 'wix-ui-tpa/cssVars'
import {useCurrencyFormatter} from '../../../../../../../../commons/hooks/currency'
import {useAppSelector} from '../../../../../hooks/runtime'
import {getEvent} from '../../../../../selectors/event'
import {getFee} from '../../../../../selectors/tax-and-fee'
import {classes} from './wix-fee.st.css'

export interface WixFeeProps {
  ticket: wix.events.ticketing.TicketDefinition
  className?: string
  overridePrice?: string
}

export const WixFee = ({ticket, className, overridePrice}: WixFeeProps) => {
  const {t} = useTranslation()
  const event = useAppSelector(getEvent)
  const abstract = isDonationTicketDefinition(ticket) && !overridePrice
  const {getFormattedMoney} = useCurrencyFormatter()
  const fee = getFee({event, ticket, t, overridePrice, abstract, getFormattedMoney})

  if (!fee) {
    return null
  }

  return (
    <Text tagName="p" className={classNames(classes.wixFee, className)} data-hook={DH.WIX_FEE}>
      {fee}
    </Text>
  )
}
