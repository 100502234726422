import {saleScheduledAndHasTickets} from '@wix/wix-events-commons-statics'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useTranslation} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import React from 'react'
import {Text} from 'wix-ui-tpa/cssVars'
import {classes} from './goes-on-sale-label.st.css'
import {GoesOnSaleProps} from './interfaces'

export const GoesOnSaleLabel = ({ticket, className}: GoesOnSaleProps) => {
  const {t} = useTranslation()
  const goesOnSale = saleScheduledAndHasTickets(ticket)

  if (!goesOnSale) {
    return null
  }

  const startDate = ticket.salePeriod.formattedSaleStartDate

  return (
    <Text tagName="div" className={classNames(classes.text, className)} role="group" data-hook={DH.TICKET_GOES_ON_SALE}>
      <p>{t('ticketsPicker.goesOnSale')}</p>
      <p>{startDate}</p>
    </Text>
  )
}
