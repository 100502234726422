import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import React from 'react'
import {Text} from 'wix-ui-tpa/cssVars'
import {a11yPriceId} from '../../a11y'
import {Labeled} from '../labeled'
import {classes} from './price.st.css'

export const Price = ({
  label,
  id,
  price,
  extraAriaLabeledBy = '',
}: {
  label: string
  id: string
  price: string
  extraAriaLabeledBy?: string
}) => {
  const priceId = a11yPriceId(id)

  return (
    <Labeled label={label} labeledElementId={priceId}>
      {({ariaLabeledBy}) => (
        <Text
          tagName="p"
          role="group"
          id={priceId}
          className={classes.price}
          data-hook={DH.PRICE}
          aria-labelledby={`${extraAriaLabeledBy} ${ariaLabeledBy}`}
        >
          {price}
        </Text>
      )}
    </Labeled>
  )
}
